.list {
  background-color: #e8a87c;
  border: 3px solid #c38d9e;
  border-radius: 15px;
  height: 40vh;
  margin: 8px auto;
  width: 90%;
}

.listItem {
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}

.textItem {
  display: block;
  margin-left: 4px;
}
