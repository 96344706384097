@import url("https://fonts.googleapis.com/css?family=Baloo+2&display=swap");

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1,
h2 {
  margin: 0;
}

.App {
  height: 100vh;
  margin: 0 auto;
  width: 90%;
  text-align: center;
  max-width: 400px;
  overflow-x: hidden;
}

.App::before {
  background: #85dcbf;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  content: "";
  height: 45%;
  left: -25%;
  position: absolute;
  top: 0;
  width: 150%;
}

.App > h1,
.App > p {
  font-family: "Baloo 2", cursive;
  position: relative;
}

.App > h1 {
  color: #e27d60;
  font-size: 32px;
  margin: 16px auto -8px;
}

.App > h1 > span {
  color: #444;
  font-family: sans-serif;
  line-height: 12px;
  font-size: 12px;
  font-style: italic;
}

@media only screen and (max-width: 600px) {
  .App > h1 {
    font-size: 24px;
  }

  .App > p {
    font-size: 12px;
    margin-bottom: 16px;
  }
}

.volume-container {
  display: inline-block;
  line-height: 36px;
  padding-left: 32px;
  vertical-align: top;
}
