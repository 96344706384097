#time-list .p-orderlist-controls {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

#time-list .p-orderlist-list-container {
  width: 80%;
}

#time-list .p-orderlist-controls button {
  margin-left: auto;
  margin-right: auto;
}

#time-list .p-orderlist-list {
  background: transparent;
  border: none;
  display: block;
  height: 100%;
  text-align: left;
  width: 95%;
}

#time-list .p-orderlist-item {
  background: #f8c89c;
  border-radius: 5px;
  overflow: hidden;
  text-align: left;
  width: 100%;
}

#time-list .p-orderlist-item:hover,
#time-list .p-orderlist-item.p-highlight {
  background: #c38d9e;
}
