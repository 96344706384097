.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 16px auto 0;
  max-width: 300px;
  text-align: left;
}

.dropdown {
  flex: 1;
  width: auto;
}

.disabledPlay {
  pointer-events: none;
  opacity: 0.6;
}
