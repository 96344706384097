.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 300px;
  text-align: left;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.container h2 {
  font-size: 14px;
  margin: 8px 0 16px;
  text-align: center;
  width: 300px;
}

.formElement {
  margin: 0 0 16px;
  width: 145px;
}

.formButton {
  margin: 0 0 16px;
  width: 143;
}

.formElementInput {
  width: 100%;
}
