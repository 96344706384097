@import url(https://fonts.googleapis.com/css?family=Baloo+2&display=swap);
body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.list-control_container__3R5pY {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 16px auto 0;
  max-width: 300px;
  text-align: left;
}

.list-control_dropdown__10gTs {
  flex: 1 1;
  width: auto;
}

.list-control_disabledPlay__ci1M3 {
  pointer-events: none;
  opacity: 0.6;
}

.list_list__1GCq6 {
  background-color: #e8a87c;
  border: 3px solid #c38d9e;
  border-radius: 15px;
  height: 40vh;
  margin: 8px auto;
  width: 90%;
}

.list_listItem__2ql_U {
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}

.list_textItem__1elm1 {
  display: block;
  margin-left: 4px;
}

#time-list .p-orderlist-controls {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

#time-list .p-orderlist-list-container {
  width: 80%;
}

#time-list .p-orderlist-controls button {
  margin-left: auto;
  margin-right: auto;
}

#time-list .p-orderlist-list {
  background: transparent;
  border: none;
  display: block;
  height: 100%;
  text-align: left;
  width: 95%;
}

#time-list .p-orderlist-item {
  background: #f8c89c;
  border-radius: 5px;
  overflow: hidden;
  text-align: left;
  width: 100%;
}

#time-list .p-orderlist-item:hover,
#time-list .p-orderlist-item.p-highlight {
  background: #c38d9e;
}

.new-list_container__2mErC {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 16px auto 0;
  max-width: 300px;
  text-align: left;
}

.new-list_inputName__235Vb {
  flex: 1 1;
  width: auto;
}

.new-list_inputName__235Vb > input {
  width: 100%;
}

.new-time-item_container__HgXqS {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 300px;
  text-align: left;
}

.new-time-item_disabled__iTRwO {
  opacity: 0.4;
  pointer-events: none;
}

.new-time-item_container__HgXqS h2 {
  font-size: 14px;
  margin: 8px 0 16px;
  text-align: center;
  width: 300px;
}

.new-time-item_formElement__1f3BP {
  margin: 0 0 16px;
  width: 145px;
}

.new-time-item_formButton__1WaZ0 {
  margin: 0 0 16px;
  width: 143;
}

.new-time-item_formElementInput__Y5nd4 {
  width: 100%;
}

.home_homeContainer__3As-Y {
  position: relative;
  z-index: 99;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1,
h2 {
  margin: 0;
}

.App {
  height: 100vh;
  margin: 0 auto;
  width: 90%;
  text-align: center;
  max-width: 400px;
  overflow-x: hidden;
}

.App::before {
  background: #85dcbf;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  content: "";
  height: 45%;
  left: -25%;
  position: absolute;
  top: 0;
  width: 150%;
}

.App > h1,
.App > p {
  font-family: "Baloo 2", cursive;
  position: relative;
}

.App > h1 {
  color: #e27d60;
  font-size: 32px;
  margin: 16px auto -8px;
}

.App > h1 > span {
  color: #444;
  font-family: sans-serif;
  line-height: 12px;
  font-size: 12px;
  font-style: italic;
}

@media only screen and (max-width: 600px) {
  .App > h1 {
    font-size: 24px;
  }

  .App > p {
    font-size: 12px;
    margin-bottom: 16px;
  }
}

.volume-container {
  display: inline-block;
  line-height: 36px;
  padding-left: 32px;
  vertical-align: top;
}

